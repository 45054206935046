import React, { useEffect, useMemo, useState } from "react";
import {
  issueSubmission,
  useServiceRequestDetails,
} from "./serviceRequest.hook";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Page from "src/components/Page";
import { BackButton } from "src/common/components/BackButton";
import oazaIcon from "src/image/logo/app_icon_white.png";
import AccountPopover from "src/layouts/dashboard/AccountPopover";
import { useAuth } from "src/hooks/useAuth.hook";
import {
  profileHeaderBar,
  roleColumns,
  ServiceRequestAssignedStaff,
  ServiceRequestFormSelectCurrentStatus,
} from "./SeviceRequestForm/ServiceRequestForm.constants";
import axios from "../../util/axios";
import { addCommentOnTicket, getAuditLog } from "src/react-query/endPoints";
import { useMutation } from "@tanstack/react-query";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import { RootTabItemStyles } from "src/theme/overrides/Tabs";
import { styled } from "@mui/material/styles";
import { BaseTable } from "../BaseTable/BaseTable";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import { format } from "date-fns";
import { alertSlice } from "src/store/alert/alert.slice";
import enums from "src/util/enums";

const RootTabStyle = styled(TabList)(({ theme, length }) => ({
  width: "98%",
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
  "& .MuiTabs-flexContainer": {
    justifyContent: "left",
  },
  "& .MuiTab-root": {
    width: `${100 / length}%`,
  },
}));

const ServiceRequestComment = () => {
  const { ticketId } = useParams();
  const { data: serviceRequestDetails, isLoading } =
    useServiceRequestDetails(ticketId);

  const tabsToShow = profileHeaderBar;
  const [selectedTab, setSelectedTab] = useState(tabsToShow[0].value);
  const user = useAuth();
  const isAdmin = useMemo(() => user?.user?.id === 1, [user]);
  const { teamId } = useSelector((state) => state.teams);
  const [inputComment, setInputComment] = useState();
  const [statusType, setStatusType] = useState();
  const submission = useMutation(issueSubmission);
  const [commentData, setCommentData] = useState();
  const [photoModal, setPhotoModal] = useState();
  const [auditHistory, setAuditHistory] = useState();
  const updatedTeamsFormTab = isAdmin
    ? [...tabsToShow]
    : tabsToShow.filter((tab) => tab.value !== "Audit History");
  const finalTableColumns = roleColumns;
  const {
    actions: { setAlert },
  } = alertSlice;
  const dispatch = useDispatch();

  useEffect(() => {
    serviceRequestDetails?.statusType
      ? setStatusType(serviceRequestDetails.statusType)
      : setStatusType("New");
    setCommentData(serviceRequestDetails?.comments);
  }, [serviceRequestDetails]);

  const getAuditData = async () => {
    const reponse = await axios.get(getAuditLog(ticketId));
    setAuditHistory(reponse.data.data);
  };

  useEffect(() => {
    if (isAdmin) {
      getAuditData();
    }
  }, [isAdmin]);

  const getCommmentedText = async () => {
    try {
      if (inputComment && inputComment !== "") {
        const url = addCommentOnTicket(ticketId);
        const response = await axios(url, {
          method: "post",
          data: {
            comment: inputComment,
            userId: user?.user?.id,
            teamId,
          },
        });
        if (response.status === 200) {
          setInputComment("");
          setCommentData((prev) => [
            ...prev,
            {
              comment: inputComment,
              companyId: teamId,
              created_at: format(new Date(), "yyyy-MM-dd hh:mm:ss a"),
              username: user?.user?.username
            },
          ]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStatusChange = (event) => {
    setStatusType(event.target.value);
  };

  const handleTabChange = (e, tab) => {
    setSelectedTab(tab);
  };

  const onClickSaveButton = () => {
    try {
      const formData = {
        statusType: statusType,
        ticketId,
      };

      submission.mutate(formData, {
        onSuccess: (response) => {
          if (
            response.status === 200 ||
            response.data.message === "Ticket update successful!"
          ) {
            getAuditData();
            dispatch(
              setAlert({
                open: true,
                message: "Ticket update successful",
                severity: enums.SEVERITY.SUCCESS,
              })
            );
          }
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Page title="Service Request | Oaza - CMS">
      <BackButton path="/servicerequest" text="Overview" />
      <TabContext value={selectedTab}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItem="center"
          sx={{ width: "100%" }}
        >
          <RootTabStyle
            indicatorColor="transparent"
            centered
            length={tabsToShow.length}
            onChange={handleTabChange}
          >
            {updatedTeamsFormTab.map((tab) => (
              <RootTabItemStyles value={tab.value} label={tab.label} />
            ))}
          </RootTabStyle>
        </Stack>
        <Card sx={{ p: 4 }}>
          <TabPanel value="Service Request">
            <Stack
              sx={{
                display: "flex",
                flexDirection: { md: "row", sm: "column" },
                gap: "2rem",
              }}
            >
              <Box sx={{ display: "flex", gap: "8px" }}>
                <Typography sx={{ fontWeight: "600" }}>Ticket ID</Typography>-{" "}
                <Typography sx={{ color: "#00AB55" }}>
                  {serviceRequestDetails?.ticket_id}
                </Typography>{" "}
              </Box>
              <Box sx={{ display: "flex", gap: "8px" }}>
                <Typography sx={{ fontWeight: "600" }}>Issue Type</Typography>-{" "}
                <Typography sx={{ color: "#00AB55" }}>
                  {serviceRequestDetails?.issueType}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "8px" }}>
                <Typography sx={{ fontWeight: "600" }}>Summary</Typography>-{" "}
                <Typography sx={{ color: "#00AB55" }}>
                  {serviceRequestDetails?.summary}
                </Typography>
              </Box>
            </Stack>

            <Stack
              mt={4}
              sx={{
                display: "grid",
                gridTemplateColumns: { sm: "1fr", md: "1fr 1fr" },
                gridGap: "2rem",
              }}
            >
              {/* Assigned to Staff Field */}
              {/* {isAdmin && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                width: "100%",
              }}
              className="assigned_staff"
            >
              <label htmlFor="assigned-to-staff" sx={{ width: "40%" }}>
                Assign to a staff
              </label>
              <Box sx={{ width: "100%" }}>
                <TextField
                  required
                  select
                  id="assigned-staff"
                  labelid="assigned-to-staff"
                  label="Assign"
                  fullWidth
                  value={serviceRequestDetails}
                  // {...getFieldProps("assignedTo")}
                  // onChange={handleChange}
                  // error={Boolean(touched.assignedTo && errors.assignedTo)}
                  // helperText={touched.assignedTo && errors.assignedTo}
                >
                  {ServiceRequestAssignedStaff.length > 0 &&
                    ServiceRequestAssignedStaff.map((item, index) => (
                      <MenuItem
                        value={item.value}
                        name={item.label}
                        key={`${index}-${item.label}`}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
            </Box>
          )} */}
              {statusType && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <TextField
                    required
                    select
                    id="status-type-select"
                    label="Status Type"
                    // disabled={!isAdmin}
                    fullWidth
                    value={statusType}
                    onChange={handleStatusChange}
                  >
                    {ServiceRequestFormSelectCurrentStatus.map(
                      (list, index) => (
                        <MenuItem
                          key={index}
                          value={list.value}
                          name={list.label}
                        >
                          {list.label}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </Box>
              )}
              <Box
                sx={{
                  width: "100%",
                  gridColumn: "2 / 1",
                }}
              >
                {serviceRequestDetails?.description && (
                  <TextField
                    disabled
                    fullWidth
                    multiline
                    rows={4}
                    label="Description"
                    defaultValue={serviceRequestDetails.description}
                  />
                )}
              </Box>
              {serviceRequestDetails?.attachment?.length > 0 && (
                <Box
                  sx={{
                    width: "100%",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    display: "flex",
                    padding: "1rem 2rem",
                    gap: "1rem",
                  }}
                >
                  {serviceRequestDetails?.attachment.map((url, index) => (
                    <img
                      key={index}
                      src={url}
                      alt="Attachments"
                      style={{
                        maxHeight: "100px",
                        maxWidth: "100px",
                      }}
                      onClick={() => setPhotoModal(url)}
                    />
                  ))}
                </Box>
              )}
              <Box>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={onClickSaveButton}
                >
                  Save
                </Button>
                <Button variant="outlined" sx={{ marginLeft: "1rem" }}>
                  Cancel
                </Button>
              </Box>
            </Stack>

            <Typography
              mt={4}
              sx={{
                borderBottom: "2px solid #00000036",
                paddingBottom: "12px",
              }}
            >
              Discussion
            </Typography>

            <Stack mt={2} sx={{ gap: "2rem" }}>
              {commentData?.length > 0 &&
                commentData.map((list, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "5% 85%",
                      placeContent: list.companyId === 1 ? "start" : "end",
                      gap: "2rem",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", gap: "5px"}}>
                      {list.companyId === 1 ? (
                        <Box
                          sx={{
                            width: "40px",
                            height: "40px",
                            border: "2px solid #00AB55",
                            borderRadius: "50%",
                            display: "flex",
                            placeContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={oazaIcon}
                            alt="Oaza Icon"
                            style={{ width: "25px", height: "25px" }}
                          />
                        </Box>
                      ) : (
                        <AccountPopover />
                      )}
                      <Typography sx={{ fontSize: "12px"}}>{list.username}</Typography>
                    </Box>
                    <Box sx={{ display: "inline-block" }}>
                      <Box
                        mt={1}
                        sx={{
                          // display: "inline-block",
                          border: "2px solid #00000036",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        {list.comment}
                      </Box>
                      <Typography sx={{ fontSize: "12px", marginTop: "6px" }}>
                        {yearFirstDateTimeFormat(list.created_at)}
                      </Typography>
                    </Box>
                  </Box>
                ))}

              <Box mt={3} sx={{ display: "flex", gap: "2rem" }}>
                <TextField
                  fullWidth
                  value={inputComment}
                  onChange={(e) => setInputComment(e.target.value)}
                />
                <Button
                  variant="contained"
                  type="submit"
                  onClick={getCommmentedText}
                >
                  Comment
                </Button>
              </Box>
            </Stack>
          </TabPanel>
          <TabPanel value="Audit History">
            <Typography
              mt={4}
              sx={{
                borderBottom: "2px solid #00000036",
                paddingBottom: "12px",
              }}
            >
              AUDIT
            </Typography>

            {auditHistory && (
              <BaseTable
                rows={auditHistory ?? []}
                columns={finalTableColumns}
                loading={false}
                getRowId={(row) => row.log_id}
                pageSize={10}
              />
            )}
          </TabPanel>
        </Card>
      </TabContext>
      {photoModal && (
        <Dialog
          onClose={() => {
            setPhotoModal("");
          }}
          aria-labelledby="customized-dialog-title"
          open={photoModal}
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              setPhotoModal("");
            }}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            <GridCloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              minHeight: "300px",
              minWidth: "600px",
              display: "flex",
              placeItems: "center",
              placeContent: "center",
              padding: "32px 40px",
            }}
          >
            <img src={photoModal} alt="Review" />
          </DialogContent>
        </Dialog>
      )}
    </Page>
  );
};

export default ServiceRequestComment;
