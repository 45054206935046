import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Box,
  Button,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  OutlinedInput,
  useTheme,
} from "@mui/material";
import { BaseTable } from "src/components/BaseTable/BaseTable";
import Page from "src/components/Page";
import { useAccess } from "src/hooks/useAccess.hook";
import { FEATURE } from "src/util/enums";
import axios from "../../util/axios";
import {
  reportListColumn,
  reportTabListToShow,
} from "./ReportTabList.constant";
import { ReactComponent as TotalEnergy } from "../../image/total_energy.svg";
import { ReactComponent as TotalRevenue } from "../../image/total_revenue.svg";
import { ReactComponent as TotalSession } from "../../image/total_session.svg";
import { styled } from "@mui/material/styles";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import { format } from "date-fns";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { reportExcelDownload, reportTabData } from "src/react-query/endPoints";
import * as XLSX from "xlsx";
import { RootTabItemStyles } from "src/theme/overrides/Tabs";
import { TabContext, TabList } from "@mui/lab";
import { downloadCSV } from "src/utils/convertAnddownloadCsv";
import { useNavigate } from "react-router-dom";
import { fetchInfinite } from "src/react-query/fetchWithError";
import { useInfiniteQuery } from "@tanstack/react-query";
import { downloadExcel } from "src/utils/downloadExcel";

const BoxStyle = styled("div")(({ theme }) => ({
  width: "100%",
  border: "1px solid #00000036",
  display: "grid",
  gridTemplateRows: "20% 80%",
  placeItems: "center",
  padding: "1rem",
  borderRadius: "10px",
}));

const RootTabStyle = styled(TabList)(({ theme, length }) => ({
  width: "98%",
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-between",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ReportTabList = () => {
  const navigate = useNavigate();
  const [reportTableData, setReportTableData] = useState();
  const allTimeMenu = ["Last 7 days", "Last 30 days", "Last 90 days"];
  const [timeInterval, setTimeInterval] = useState("Last 7 days");
  const [tableData, setTableData] = useState();
  const [totalData, setTotalData] = useState({
    totalEnergy: "",
    totalRevenue: "",
    totalSession: "",
  });
  const [getDate, setGetDate] = useState({ startDate: "", endDate: "" });
  const [uniqueChargeBoxIdValues, setUniqueChargeBoxIdValues] = useState();
  const [uniquePayoutStatus, setUniquePayoutStatus] = useState();
  const isReadOnly = useAccess(FEATURE.REPORT, "RP");
  const finalTableColumns = reportListColumn(isReadOnly);
  const { teamId } = useSelectedTeam();
  const [selectedTab, setSelectedTab] = useState("Statement Report Tab");
  const tabsToShow = [
    { value: "Statement Report Tab", label: "Statement Report Tab" },
  ];
  const [chargeBoxName, setChargeBoxName] = useState([]);
  const [payoutStatus, setPayoutStatus] = useState([]);
  const reportTabListToShowData = reportTabListToShow();
  const [uniqueTeamName, setUniqueTeamName] = useState();
  const [uniqueCompanyName, setUniqueCompanyName] = useState();
  const [selectedTeamName, setSelectedTeamName] = useState([]);
  const [selectedCompanyName, setSelectedCompanyName] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [pageSize, setPageSize] = useState(10);
;

  const rowOrCellClickRedirect = ({ id }) => {
    // if(isReadOnly) return;
    navigate(`/transactions/${id}`);
  };

  useEffect(() => handleChangeTimeInterval("Last 7 days"), []);

  const getFilteredDataForTable = (data) => {
    let chargeBoxValue = [...new Set(data.map((item) => item.chargeboxId))];
    setUniqueChargeBoxIdValues(chargeBoxValue);
    let payoutStatus = [...new Set(data.map((item) => item.status))];
    setUniquePayoutStatus(payoutStatus);
    let teamName = [...new Set(data.map((item) => item.teamName))];
    setUniqueTeamName(teamName);
  };

  const {
    data,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetching,
    isRefetching,
  } = useInfiniteQuery(["viewReport", { url: reportTabData(teamId, getDate.startDate, getDate.endDate) }], fetchInfinite, {
    enabled: !!getDate.startDate && !!getDate.endDate && !!teamId,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length === 0) {
        return;
      }
      return {page: pages.length + 1, pageSize};
    },
  });

  useEffect(() => {
    if (!isFetching && data?.pages[0].length > 0) {
      let reportData = data.pages.flat();
          getHeaderData(reportData);
          setReportTableData(reportData);
          getFilteredDataForTable(reportData);
          if (teamId === 1) {
            let companyName = [
              ...new Set(reportData.map((item) => item.parentCompanyName)),
            ];
            setUniqueCompanyName(companyName);
          }
    }
  }, [isFetching, data]);

  const getHeaderData = (reportList) => {
    let energySum = 0;
    let revenueSum = 0;
    reportList.map((sum) => {
      energySum += sum.totalEnergy;
      revenueSum += Number(
        sum?.gst !== "" ? sum.payoutWithTax : sum.payoutWithoutTax
      );
    });
    setTotalData({
      totalEnergy: parseFloat(energySum).toFixed(2),
      totalRevenue: parseFloat(revenueSum).toFixed(2),
      totalSession: reportList.length,
    });
    setTableData(reportList);
  };

  const handleChangeTimeInterval = (value) => {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    let startDate = format(date, "yyyy-MM-dd HH:mm:ss");
    setTimeInterval(value);
    switch (value) {
      case "Today":
        {
          let endDate = new Date();
          setGetDate({
            startDate: startDate,
            endDate: format(endDate, "yyyy-MM-dd HH:mm:ss"),
          });
        }
        break;
      case "Yesterday":
        {
          let ystrDate = new Date().setDate(new Date().getDate() - 1);
          let actualDate = format(
            new Date(ystrDate).setHours(0, 0, 0, 0),
            "yyyy-MM-dd HH:mm:ss"
          );
          setGetDate({
            startDate: actualDate,
            endDate: format(
              new Date(actualDate).setHours(23, 59, 59),
              "yyyy-MM-dd HH:mm:ss"
            ),
          });
        }
        break;
      case "Last 7 days":
        {
          let endDate = new Date().setDate(new Date().getDate() - 7);
          setGetDate({
            startDate: format(
              new Date(endDate).setHours(0, 0, 0, 0),
              "yyyy-MM-dd HH:mm:ss"
            ),
            endDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          });
        }
        break;
      case "Last 30 days":
        {
          let endDate = new Date().setDate(new Date().getDate() - 30);
          setGetDate({
            startDate: format(
              new Date(endDate).setHours(0, 0, 0, 0),
              "yyyy-MM-dd HH:mm:ss"
            ),
            endDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          });
        }
        break;
      case "Last 90 days":
        {
          let endDate = new Date().setDate(new Date().getDate() - 90);
          setGetDate({
            startDate: format(
              new Date(endDate).setHours(0, 0, 0, 0),
              "yyyy-MM-dd HH:mm:ss"
            ),
            endDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          });
        }
        break;
      default:
        break;
    }
  };

  const handleSearching = (event) => {
    const dataToSearchFrom = reportTableData;
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = dataToSearchFrom.reduce(
        (filteredDataArr, currentObj) => {
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => reportTabListToShowData.includes(key))
            .map(([key, value]) =>
              key === "starttime" || key === "stoptime"
                ? yearFirstDateTimeFormat(value).toString()
                : value
                ? value.toString().toLowerCase()
                : ""
            );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      setTableData(filteredDataArr);
    } else setTableData([...reportTableData]);
  };

  const saveToExcel = async () => {
    try {
      const response = await axios.get(reportExcelDownload(teamId, getDate.startDate, getDate.endDate), {
          responseType: 'blob',
      });

      downloadExcel(response.data, "report")

  } catch (error) {
      console.error('Error downloading the Excel file', error);
  }
  };

  const handleTabChange = (e, tab) => {
    setSelectedTab(tab);
  };

  function convertArrayOfObjectsToCSV(arrayOfObjects) {
    if (
      !arrayOfObjects ||
      !Array.isArray(arrayOfObjects) ||
      arrayOfObjects.length === 0
    ) {
      return "";
    }
    const rows = arrayOfObjects.map((object) => {
      const values = Object.values(object).map((value) => `"${value}"`);
      return values.join("; ");
    });
    return rows.join("\n");
  }

  const handleExport = () => {
    const csv = convertArrayOfObjectsToCSV(tableData);
    downloadCSV(csv, "Transaction_Report");
  };

  useEffect(() => {
    if (reportTableData?.length > 0) {
      let filteredData =
        chargeBoxName?.length > 0 ||
        payoutStatus?.length > 0 ||
        selectedCompanyName?.length > 0 ||
        selectedTeamName?.length > 0
          ? reportTableData.filter((item) => {
              const chargeboxMatch =
                chargeBoxName.length > 0
                  ? chargeBoxName.includes(item.chargeboxId)
                  : true;
              const payoutStatusMatch =
                payoutStatus.length > 0
                  ? payoutStatus.includes(item.status)
                  : true;
              const companyNameMatch =
                selectedCompanyName.length > 0
                  ? selectedCompanyName.includes(item.parentCompanyName)
                  : true;
              const teamNameMatch =
                selectedTeamName.length > 0
                  ? selectedTeamName.includes(item.teamName)
                  : true;

              return (
                chargeboxMatch &&
                payoutStatusMatch &&
                companyNameMatch &&
                teamNameMatch
              );
            })
          : reportTableData;

      getHeaderData(filteredData);
      if (selectedCompanyName?.length > 0) {
        if (selectedTeamName.length === 0) {
          let teamName = [
            ...new Set(filteredData.map((item) => item.teamName)),
          ];
          setUniqueTeamName(teamName);
        }
        if (chargeBoxName?.length === 0) {
          let chargeBoxValue = [
            ...new Set(filteredData.map((item) => item.chargeboxId)),
          ];
          setUniqueChargeBoxIdValues(chargeBoxValue);
        }
        if (payoutStatus?.length === 0) {
          let payoutStatus = [
            ...new Set(filteredData.map((item) => item.status)),
          ];
          setUniquePayoutStatus(payoutStatus);
        }
      }
      if (selectedTeamName?.length > 0) {
        if (chargeBoxName?.length === 0) {
          let chargeBoxValue = [
            ...new Set(filteredData.map((item) => item.chargeboxId)),
          ];
          setUniqueChargeBoxIdValues(chargeBoxValue);
        }
        if (payoutStatus?.length === 0) {
          let payoutStatus = [
            ...new Set(filteredData.map((item) => item.status)),
          ];
          setUniquePayoutStatus(payoutStatus);
        }
      }
      if (chargeBoxName?.length > 0) {
        if (payoutStatus?.length === 0) {
          let payoutStatus = [
            ...new Set(filteredData.map((item) => item.status)),
          ];
          setUniquePayoutStatus(payoutStatus);
        }
      } else if (
        selectedTeamName.length === 0 &&
        chargeBoxName?.length === 0 &&
        payoutStatus?.length === 0
      ) {
      }
    }
  }, [chargeBoxName, payoutStatus, selectedCompanyName, selectedTeamName]);

  const handleChangeChargeBox = (event, filterType) => {
    const {
      target: { value },
    } = event;

    switch (filterType) {
      case "chargeboxId":
        setChargeBoxName(typeof value === "string" ? value.split(",") : value);
        break;
      case "status":
        setPayoutStatus(typeof value === "string" ? value.split(",") : value);
        break;
      case "companyName":
        setSelectedCompanyName(
          typeof value === "string" ? value.split(",") : value
        );
        break;
      case "teamName":
        setSelectedTeamName(
          typeof value === "string" ? value.split(",") : value
        );
        break;
      default:
        break;
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
    setHasMore(true);
  };

  const handlePageChange = (newPage) => {
    if ((newPage + 1) * pageSize >= tableData.length && hasMore) {
      setPage(newPage);
      fetchNextPage();
    }
  };

  return (
    <Page title="Report | Smart-CMS">
      <TabContext value={selectedTab}>
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ width: "100%" }}
        >
          <RootTabStyle
            indicatorColor="transparent"
            centered
            length={tabsToShow.length}
            onChange={handleTabChange}
          >
            {tabsToShow.map((tab, index) => (
              <RootTabItemStyles key={index} value={tab.value} label={tab.label} />
            ))}
          </RootTabStyle>
        </Stack>
        <Card sx={{ p: 4 }}>
          <Stack direction="row" sx={{ gap: "4rem" }}>
            <BoxStyle>
              <Typography
                mt={2}
                mb={1}
                sx={{ fontWeight: "600", fontSize: "16px" }}
              >
                Total Energy
              </Typography>
              <Box sx={{ display: "flex", placeItems: "center", gap: "2rem" }}>
                <TotalEnergy />
                <Box sx={{ color: "#00AB55" }}>
                  <Typography
                    sx={{
                      display: "inline-block",
                      fontWeight: "600",
                      fontSize: "2rem",
                    }}
                  >
                    {totalData.totalEnergy}
                  </Typography>
                  <Typography sx={{ display: "inline-block" }}>KwH</Typography>
                </Box>
              </Box>
            </BoxStyle>
            <BoxStyle>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  placeItems: "center",
                }}
              >
                <Typography
                  mb={1}
                  mt={2}
                  sx={{ fontWeight: "600", fontSize: "16px" }}
                >
                  Total Revenue
                </Typography>
                <Typography
                  mb={1}
                  sx={{ marginTop: "-10px", fontSize: "12px" }}
                >
                  (CPO Payout)
                </Typography>
              </Box>
              <Box sx={{ display: "flex", placeItems: "center", gap: "2rem" }}>
                <TotalRevenue />
                <Typography
                  sx={{ color: "#00AB55", fontWeight: "600", fontSize: "2rem" }}
                >
                  {totalData.totalRevenue}
                </Typography>
              </Box>
            </BoxStyle>
            <BoxStyle>
              <Typography
                mb={1}
                mt={2}
                sx={{ fontWeight: "600", fontSize: "16px" }}
              >
                Total Session
              </Typography>
              <Box sx={{ display: "flex", placeItems: "center", gap: "2rem" }}>
                <TotalSession />
                <Typography
                  sx={{ color: "#00AB55", fontWeight: "600", fontSize: "2rem" }}
                >
                  {totalData.totalSession}
                </Typography>
              </Box>
            </BoxStyle>
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">All Time</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={timeInterval}
                  label="All Time"
                  value={timeInterval}
                  onChange={(event) =>
                    handleChangeTimeInterval(event.target.value)
                  }
                >
                  {allTimeMenu.map((data) => (
                    <MenuItem key={data} value={data}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Stack>
          <Box>
            <Typography
              mt={4}
              sx={{
                borderBottom: "2px solid #00000036",
                paddingBottom: "12px",
              }}
            >
              STATEMENT REPORT
            </Typography>
            <Stack
              mt={2}
              mb={4}
              sx={{
                gap: "1rem",
                alignItems: "center",
                display: "grid",
                gridTemplateColumns: { sm: "1fr 1fr 1fr", md: "1fr 1fr 1fr" },
              }}
            >
              {tableData?.length > 0 && (
                <div>
                  <Button
                    variant="contained"
                    onClick={saveToExcel}
                    sx={{ whiteSpace: "pre" }}
                  >
                    Export To Excel
                  </Button>
                  {/* <Button
                    variant="contained"
                    onClick={handleExport}
                    sx={{ whiteSpace: "pre", marginLeft: "2rem" }}
                  >
                    Export to CSV
                  </Button> */}
                </div>
              )}
              {(chargeBoxName?.length > 0 ||
                payoutStatus?.length > 0 ||
                selectedCompanyName?.length > 0 ||
                selectedTeamName?.length > 0 ||
                tableData?.length > 0) &&
                reportTableData?.length > 0 && (
                  <>
                    {teamId === 1 && (
                      <>
                        <Box sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-name-label">
                            Company Name
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={selectedCompanyName}
                            onChange={(e) =>
                              handleChangeChargeBox(e, "companyName")
                            }
                            input={<OutlinedInput label="Company Name" />}
                            MenuProps={MenuProps}
                            sx={{ width: "300px" }}
                          >
                            {uniqueCompanyName?.length > 0 &&
                              uniqueCompanyName.map((name) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  //   style={getStyles(name,chargeBoxName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                          </Select>
                        </Box>
                        <Box sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-name-label">
                            Team Name
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={selectedTeamName}
                            onChange={(e) =>
                              handleChangeChargeBox(e, "teamName")
                            }
                            input={<OutlinedInput label="Team Name" />}
                            MenuProps={MenuProps}
                            sx={{ width: "300px" }}
                          >
                            {uniqueTeamName?.length > 0 &&
                              uniqueTeamName.map((name) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  //   style={getStyles(name,chargeBoxName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                          </Select>
                        </Box>
                      </>
                    )}
                    <Box sx={{ m: 1, width: 300 }}>
                      <InputLabel id="demo-multiple-name-label">
                        Charge Box Id
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={chargeBoxName}
                        onChange={(e) =>
                          handleChangeChargeBox(e, "chargeboxId")
                        }
                        input={<OutlinedInput label="Charge Box Id" />}
                        MenuProps={MenuProps}
                        sx={{ width: "300px" }}
                      >
                        {uniqueChargeBoxIdValues?.length > 0 &&
                          uniqueChargeBoxIdValues.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              //   style={getStyles(name,chargeBoxName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Box>
                    <Box sx={{ m: 1, width: 300 }}>
                      <InputLabel id="demo-multiple-name-label">
                        Payout Status
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={payoutStatus}
                        onChange={(e) => handleChangeChargeBox(e, "status")}
                        input={<OutlinedInput label="Charge Box Id" />}
                        MenuProps={MenuProps}
                        sx={{ width: "300px" }}
                      >
                        {uniquePayoutStatus?.length > 0 &&
                          uniquePayoutStatus.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              //   style={getStyles(name,chargeBoxName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Box>
                  </>
                )}
            </Stack>
            {!tableData?.length > 0 && (
              <Stack>
                <Typography sx={{ color: "red" }}>
                  This team have no transaction for {timeInterval} session. So
                  no statement generated.
                </Typography>
              </Stack>
            )}
            <CommonSearchbar
              placeholder="Search"
              searchFunc={(e) => handleSearching(e)}
            />
            <BaseTable
              isReadOnly={isReadOnly}
              rows={tableData ?? []}
              columns={finalTableColumns}
              loading={false}
              getRowId={(row) => row.startTime}
              pageSize={10}
              showExportCsvBtn={false}
              rowOrCellClickRedirect={rowOrCellClickRedirect}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  overflow: "hidden   !important",
                  lineHeight: "20px   !important",
                  whiteSpace: "normal  !important",
                },
              }}
            />
          </Box>
        </Card>
      </TabContext>
    </Page>
  );
};

export default ReportTabList;
