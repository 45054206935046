import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { BaseTable } from "src/components/BaseTable/BaseTable";
import Page from "src/components/Page";
import { useSelectedTeam } from "src/hooks/useSelectedTeam";
import { TransactionList } from "../transactions.hook";
import { transactionsListColumns } from "./TransactionsList.constants";
import { useNavigate } from "react-router-dom";
import { useAccess } from "src/hooks/useAccess.hook";
import { FEATURE } from "src/util/enums";
import { CommonSearchbar } from "src/layouts/dashboard/Searchbar";
import { useState, useEffect, useMemo } from "react";
import { yearFirstDateTimeFormat } from "src/utils/formatTime";
import { downloadExcel } from "src/utils/downloadExcel";
import {
  getAllExistedTeam,
  transactionExcelDownload,
} from "src/react-query/endPoints";
import axios from "../../../util/axios";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAuth } from "src/hooks/useAuth.hook";

export const TransactionsList = () => {
  const navigate = useNavigate();
  const { teamId } = useSelectedTeam();
  const isReadOnly = useAccess(FEATURE.TRANSACTION, "R");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const initialColumns = transactionsListColumns(isReadOnly);
  const finalTableColumns = initialColumns.filter(
    ({ field }) => field !== "status" && field !== "notes"
  );
  const [tableData, setTableData] = useState([]);
  const { user } = useAuth();
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [allTeamsData, setAllTeamsData] = useState();
  const [dummyTeamData, setDummyTeamData] = useState();
  const [selectedGPTeam, setSelectedGPTeam] = useState([]);
  const companyType = useMemo(() => {
    return user?.company?.companyType;
  }, [user]);

  const { data, isFetching } = TransactionList({
    teamId,
    page,
    pageSize,
    filterteam: selectedTeam,
    filterGPTeam: selectedGPTeam,
    companyType,
  });
  const rowOrCellClickRedirect = ({ id }) => {
    // if(isReadOnly) return;
    navigate(`/transactions/${id}`);
  };

  useEffect(() => {
    if (user?.company?.id === teamId) {
      setAllTeamsData(dummyTeamData);
    } else {
      const team = dummyTeamData?.filter(
        (list) => list.parentCompanyId === teamId
      );
      setAllTeamsData(team);
    }
  }, [teamId, dummyTeamData]);

  useEffect(() => {
    try {
      const getAllTeam = async () => {
        const response = await axios.get(getAllExistedTeam());
        setAllTeamsData(response.data.data);
        setDummyTeamData(response.data.data);
      };
      companyType === "GP" && getAllTeam();
    } catch (err) {
      console.log(err);
    }
  }, [companyType]);

  useEffect(() => {
    setTableData(data?.transactions);
  }, [data]);

  const handlePageSizeChange = (newPageSize) => {
    if (selectedGPTeam.length > 0 || selectedTeam.length > 0) return;
    setPageSize(newPageSize);
  };

  const handlePageChange = (newPage) => {
    if (selectedGPTeam.length > 0 || selectedTeam.length > 0) return;
    setPage(newPage + 1);
  };

  const handleSearching = (event) => {
    const dataToSearchFrom = data?.transactions;
    const searchValue = event.target.value.trim().toString().toLowerCase();
    if (searchValue) {
      const filteredDataArr = dataToSearchFrom.reduce(
        (filteredDataArr, currentObj) => {
          const relevantFields = [
            "transactionId",
            "email",
            "phone",
            "chargepoint_id",
            "connectorId",
            "payment_type",
            "totalAmountPaid",
            "totalAmountCharged",
            "totalRefund",
            "starttime",
            "stoptime",
            "status",
          ];
          const allValuesArr = Object.entries(currentObj)
            .filter(([key, value]) => relevantFields.includes(key))
            .map(([key, value]) =>
              key === "starttime" || key === "stoptime"
                ? yearFirstDateTimeFormat(value).toString()
                : value
                ? value.toString().toLowerCase()
                : ""
            );
          const searchResult = allValuesArr.find((value) =>
            value.includes(searchValue)
          );
          if (searchResult) {
            filteredDataArr = [...filteredDataArr, currentObj];
          }
          return filteredDataArr;
        },
        []
      );
      setTableData(filteredDataArr);
    } else setTableData(data?.transactions);
  };

  const saveToExcel = async () => {
    try {
      const response = await axios.get(transactionExcelDownload(teamId), {
        responseType: "blob",
      });

      downloadExcel(response.data, "transaction");
    } catch (error) {
      console.error("Error downloading the Excel file", error);
    }
  };

  const onChangeCompany = (event) => {
    setSelectedTeam(event.target.value);
    setSelectedGPTeam([]);
    if (companyType === "GP" && event.target.value.length > 0) {
      const teamFilterValue = dummyTeamData.filter((filter) =>
        event.target.value.includes(filter.parentCompanyId)
      );
      setAllTeamsData(teamFilterValue);
    } else setAllTeamsData(dummyTeamData);
  };

  return (
    <Page title="Transactions | Smart-CMS">
      <Card sx={{ py: 2, px: 1, width: "103%" }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gridColumnGap: "2rem",
            alignItems: "center",
          }}
        >
          <CommonSearchbar
            placeholder="Search"
            searchFunc={(e) => handleSearching(e)}
          />
          {(companyType === "GP" || companyType === "P") &&
            user?.company?.id === teamId && (
              <Box sx={{ width: "100%" }}>
            <FormControl fullWidth  size="small">
                  <InputLabel id="demo-simple-select-label">
                    {companyType === "GP" ? "Select Company" : "Select Team"}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    multiple
                    // label="All Time"
                    value={selectedTeam}
                    onChange={(event) => onChangeCompany(event)}
                  >
                    {user?.role
                      ?.filter((team) => team.teamId !== user?.company?.id)
                      ?.map((team) => (
                        <MenuItem
                          key={team.teamId}
                          value={team.teamId}
                          sx={{
                            pl: 4,
                          }}
                        >
                          {team.teamName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          {companyType === "GP" && (
            <Box sx={{ width: "100%"}}>
              <FormControl fullWidth  size="small">
                <InputLabel id="demo-simple-select-label">
                  Select Team
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  multiple
                  // label="All Time"
                  value={selectedGPTeam}
                  onChange={(event) => setSelectedGPTeam(event.target.value)}
                >
                  {allTeamsData?.map((team) => (
                    <MenuItem
                      key={team.companyId}
                      value={team.companyId}
                      sx={{
                        pl: 4,
                      }}
                    >
                      {team.companyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {tableData?.length > 0 && (
            <Button
              variant="contained"
              onClick={saveToExcel}
              sx={{ whiteSpace: "pre", mr: 4 }}
            >
              Export
            </Button>
          )}
        </Box>

        <BaseTable
          isReadOnly={isReadOnly}
          rows={tableData ?? []}
          columns={finalTableColumns}
          loading={isFetching}
          getRowId={(row) => row.transactionId}
          pageSize={pageSize}
          showExportCsvBtn={false}
          csvMorphemes="transactions"
          csvColumns={initialColumns}
          rowOrCellClickRedirect={rowOrCellClickRedirect}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalRows={data?.totalRecord || 0}
          serverRowFetcher={
            selectedGPTeam.length > 0 || selectedTeam.length > 0 ? false : true
          }
          rowsPerPageOptions={[10, 30, 50, 100]}
          sx={{
            ".MuiDataGrid-columnHeaderTitle": {
              overflow: "hidden   !important",
              lineHeight: "20px   !important",
              whiteSpace: "normal  !important",
            },
          }}
        />
      </Card>
    </Page>
  );
};
