import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CrossIcon from "@iconify/icons-eva/close-outline";
import Divider from "@mui/material/Divider";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
// import ImgPreviewModal from "src/components/ImagePreviewModal/ImgPreviewModal";
import { serviceRequestFormValidationSchema } from "src/util/validationSchemas";
import { fileToBase64 } from "src/utils/fileConvertBase64";
import {
  issueImgUpload,
  issueSubmission,
  useServiceRequestDetails,
} from "../serviceRequest.hook";
import {
  ServiceRequestFormSelectItems,
  ServiceRequestFormSelectCurrentStatus,
  ServiceRequestAssignedStaff,
} from "./ServiceRequestForm.constants";
import sidebarConfig from "src/layouts/dashboard/SidebarConfig";
import enums, { FEATURE } from "src/util/enums";
import { Loader } from "src/components/Loader";
import { is } from "src/util/is";
import { alertSlice } from "src/store/alert/alert.slice";
import { useAuth } from "src/hooks/useAuth.hook";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { GridCloseIcon } from "@mui/x-data-grid";

const ServiceRequestForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { ticketId } = useParams();
  const { teamId } = useSelector((state) => state.teams);
  const isCreationOperation = useMatch("servicerequest/new");
  const {
    actions: { setAlert },
  } = alertSlice;
  const { data: serviceRequestDetails, isLoading } =
    useServiceRequestDetails(ticketId);
  const initialFormState = {
    email: user?.email,
    phone: "",
    issueType: "",
    statusType: "",
    assignedTo: "",
    description: "",
    summary: "",
    teamId,
    attachment: [],
  };
  const [imageUploadPending, setImageUploadPending] = useState(false);
  const [uploadedImgUrls, setUploadedImageUrls] = useState([]);
  const [beforeUploadImg, setBeforeUploadImg] = useState([]);
  const imageUploadInputRef = useRef();
  const imgUpload = useMutation(issueImgUpload);
  const submission = useMutation(issueSubmission);
  const [photoModal, setPhotoModal] = useState({ open: false, src: "" });

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setBeforeUploadImg([...beforeUploadImg, file]);
    formik.setFieldValue("attachment", beforeUploadImg);
  };

  const uploadImageHandler = async (file) => {
    const fileInBase64 = await fileToBase64(file);

    try {
      const response = await imgUpload.mutateAsync({
        image: fileInBase64,
        folder: "service_request",
      });
      return response.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const cancelImageUpload = () => {
    formik.setFieldValue("attachment", {});
    closePreviewModal();
  };

  const closePreviewModal = () =>
    setImagePreview({ ...imagePreview, open: false });

  const resetFormBtnHandler = () => {
    formik.setFieldValue("attachment", {});
    setImagePreview({ ...imagePreview, value: "" });
  };

  const uploadImages = async () => {
    try {
      const promises = beforeUploadImg.map((file) => uploadImageHandler(file));
      const results = await Promise.all(promises); // Use Promise.all for better error handling
      return results;
    } catch (error) {
      return [];
    }
  };

  const submitForm = async (formData) => {
    setImageUploadPending(true);
    let formPayload = { ...formData};

    // upload image to s3

    try {
      const urls = await uploadImages();

      formPayload = { ...formPayload, attachment: urls, statusType: "New" };
      if (!is.nullOrUndefined(ticketId) && !isCreationOperation) {
        formPayload = { ...formPayload, ticketId };
      }
      submission.mutate(formPayload, {
        onSuccess: (response) => {
          if (
            response.status === 200 ||
            response.data.message === "Ticket update successful!"
          ) {
            setImageUploadPending(false);
            setImagePreview({ ...imagePreview, value: "" });
            navigate(sidebarConfig[FEATURE.SERVICEREQUEST].path);
            dispatch(
              setAlert({
                open: true,
                message: response.data.message,
                severity: enums.SEVERITY.SUCCESS,
              })
            );
          } else setImageUploadPending(false);
          // console.log(sidebarConfig[FEATURE.SERVICEREQUEST].path);
        },
      });
    } catch (error) {
      setImageUploadPending(false);
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: initialFormState,
    validationSchema: serviceRequestFormValidationSchema,
    onSubmit: submitForm,
    onReset: resetFormBtnHandler,
  });

  const {
    getFieldProps,
    handleChange,
    handleSubmit,
    handleReset,
    touched,
    errors,
    resetForm,
    values,
  } = formik;
  const [imagePreview, setImagePreview] = useState({
    open: false,
    value: "",
    imagePreviewBtns: [
      {
        btnLabel: "Upload",
        action: uploadImageHandler,
        params: true,
      },
      {
        btnLabel: "Cancel",
        action: cancelImageUpload,
      },
    ],
  });

  useEffect(() => {
    if (serviceRequestDetails) {
      const {
        attachment,
        description,
        summary,
        email,
        issueType,
        phone,
        assignedTo,
      } = serviceRequestDetails;
      setUploadedImageUrls(attachment);
      const formData = {
        description,
        summary,
        email,
        issueType,
        phone,
        statusType: "New",
        assignedTo,
      };
      resetForm({ values: formData });
    }
  }, [serviceRequestDetails, resetForm]);

  if (isLoading && ticketId) {
    return <Loader />;
  }

  return (
    <>
      <Paper elevation={2}>
        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            onReset={handleReset}
          >
            <Grid
              container
              direction="column"
              spacing={2}
              sx={{ height: "auto", width: "100%", p: 2 }}
            >
              <Grid item>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: { xs: 3, sm: 12 },
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <label
                      htmlFor="issue-type-select"
                      style={{ whiteSpace: "pre" }}
                    >
                      Issue Type:
                    </label>
                    <TextField
                      required
                      select
                      id="issue-type-select"
                      labelid="issue-type-label"
                      label="Issue Type"
                      fullWidth
                      {...getFieldProps("issueType")}
                      onChange={handleChange}
                      error={Boolean(touched.issueType && errors.issueType)}
                      helperText={touched.issueType && errors.issueType}
                    >
                      {ServiceRequestFormSelectItems.length > 0 &&
                        ServiceRequestFormSelectItems.map((item, index) => (
                          <MenuItem
                            value={item.value}
                            name={item.label}
                            key={`${index}-${item.label}`}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <label
                      htmlFor="status-type-select"
                      style={{ whiteSpace: "pre" }}
                    >
                      Status Type:
                    </label>
                    <TextField
                      required
                      select
                      id="status-type-select"
                      labelid="status-type"
                      label="New"
                      disabled
                      fullWidth
                      {...getFieldProps("statusType")}
                      onChange={handleChange}
                      error={Boolean(touched.statusType && errors.statusType)}
                      helperText={touched.statusType && errors.statusType}
                    >
                      {ServiceRequestFormSelectCurrentStatus.length > 0 &&
                        ServiceRequestFormSelectCurrentStatus.map(
                          (item, index) => (
                            <MenuItem
                              value={item.value}
                              name={item.label}
                              key={`${index}-${item.label}`}
                            >
                              {item.label}
                            </MenuItem>
                          )
                        )}
                    </TextField>
                  </Box>
                  <Box>
                    <ImageUploadView
                      attachments={beforeUploadImg}
                      setPhotoModal={setPhotoModal}
                      setAttachments={setBeforeUploadImg}
                    />
                    <Stack>
                        <TextField
                          placeholder={"No file chosen"}
                          onChange={handleChange}
                          helperText={`Upload an image related to query.
                   (Max. 10MB - .JPEG,.JPG,.PNG)`}
                          disabled
                        />
                    </Stack>
                    <LoadingButton
                      variant="contained"
                      onClick={() => imageUploadInputRef.current.click()}
                      // loading={imageUploadPending}
                      loadingIndicator="Loading…"
                      endIcon={<Icon icon="uil:image-upload"></Icon>}
                      sx={{ marginTop: "20px" }}
                    >
                      Upload
                      <input
                        accept="image/jpeg,image/png,image/jpg"
                        type="file"
                        hidden
                        id="issue-image"
                        ref={imageUploadInputRef}
                        onChange={handleImageUpload}
                      />
                    </LoadingButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                {/* <Typography my={2} variant="h6">
                    {" "}
                    Issue
                  </Typography> */}
                <Stack direction="column">
                  {/* image upload */}

                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        mt: 4,
                        mb: 2,
                        width: "100%",
                      }}
                    >
                      <TextField
                        fullWidth
                        required
                        multiline
                        // sx={{
                        //   width: {
                        //     xs: "300px",
                        //     sm: "400px",
                        //     md: "400px",
                        //     lg: "400px",
                        //   },
                        // }}
                        rows={6}
                        id="issue-summary"
                        label="Summary"
                        {...getFieldProps("summary")}
                        onChange={handleChange}
                        error={Boolean(touched.summary && errors.summary)}
                        helperText={touched.summary && errors.summary}
                      />
                    </Box>
                    <Box
                      sx={{
                        mt: 4,
                        mb: 2,
                        width: "100%",
                      }}
                    >
                      <TextField
                        required
                        multiline
                        // sx={{
                        //   width: {
                        //     xs: "300px",
                        //     sm: "400px",
                        //     md: "400px",
                        //     lg: "400px",
                        //   },
                        // }}
                        fullWidth
                        rows={6}
                        id="issue-description"
                        label="Description of issue"
                        {...getFieldProps("description")}
                        onChange={handleChange}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        helperText={touched.description && errors.description}
                      />
                    </Box>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Button
                      variant="contained"
                      disabled={imageUploadPending}
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Button variant="outlined" type="reset">
                      Reset
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Paper>
      {/* {imagePreview.open && (
        <ImgPreviewModal
          imageObj={imagePreview}
          buttonsArray={imagePreview.imagePreviewBtns}
        />
      )} */}

      {photoModal && (
        <Dialog
          onClose={() => {
            setPhotoModal({ open: false, src: "" });
          }}
          aria-labelledby="customized-dialog-title"
          open={photoModal.open}
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              setPhotoModal({ open: false, src: "" });
            }}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            <GridCloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              minHeight: "300px",
              minWidth: "600px",
              display: "flex",
              placeItems: "center",
              placeContent: "center",
              padding: "32px 40px",
            }}
          >
            <img src={photoModal.src} alt="Review" />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const ImageUploadView = ({ attachments, setPhotoModal, setAttachments }) => {
  if (attachments.length === 0 || !attachments || !setAttachments) {
    return null;
  }
  return (
    <Grid container spacing={2}>
      {attachments.map((attachment, index) => {
        if(attachment){
          let url = URL.createObjectURL(attachment, index);
          // extract name from file
          return (
            <Box
            key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignItems: "center",
                gap: "10px",
                marginBottom: "10px",
                padding: "10px",
              }}
            >
              <Stack direction="row" spacing={2}>
                <Badge
                  sx={{ height: "100%", cursor: "pointer" }}
                  color="primary"
                  badgeContent={
                    <Icon
                      onClick={() => {
                        setAttachments(attachments.filter((_, i) => i !== index));
                      }}
                      icon={CrossIcon}
                    />
                  }
                  // variant="dot"
                  className="cross-badge"
                >
                  <ImageListItem sx={{ width: 100 }}>
                    <img
                      onClick={() => {
                        setPhotoModal({
                          open: true,
                          src: url,
                        });
                      }}
                      src={url}
                      alt={"issue"}
                      loading="lazy"
                    />
                  </ImageListItem>
                </Badge>
              </Stack>
  
              <Box></Box>
            </Box>
          );

        }
      })}
    </Grid>
  );
};

export default ServiceRequestForm;
