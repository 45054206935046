import * as Yup from "yup";
import { chargingProfileDropdownOptions } from "../ChargingProfile.constants";
export const formConfig = new Map([
  [
    "firstRowContainer",
    [
      {
        fieldName: "charging_profile_pk",
        label: "Charging Profile ID",
        fieldType: "TEXT",
        valueType: "number",
        validations: Yup.string().optional(),
        readOnly: true,
      },
      {
        fieldName: "stack_level",
        label: "Stack Level",
        fieldType: "TEXT",
        valueType: "number",
        validations: Yup.number().min(0,'Must be atleast equal or greater than 0').required("This field is required"),
      },
      {
        fieldName: "description",
        label: "Description",
        fieldType: "TEXT",
        valueType: "string",
        validations: Yup.string().required("This field is required"),
      },
    ],
  ],
  [
    "secondRowContainer",
    [
      {
        fieldName: "charging_profile_purpose",
        label: "Profile Purpose",
        fieldType: "DROPDOWN",
        options: chargingProfileDropdownOptions["purpose"],
        valueType: "string",
        validations: Yup.string().required("This field is required"),
      },
      {
        fieldName: "charging_profile_kind",
        label: "Profile Kind",
        fieldType: "DROPDOWN",
        options: chargingProfileDropdownOptions["profile_kind"],
        valueType: "string",
        validations: Yup.string().required("This field is required"),
      },
      {
        fieldName: "recurrency_kind",
        label: "Recurrency Kind",
        fieldType: "DROPDOWN",
        options: chargingProfileDropdownOptions["recurrency_kind"],
        valueType: "string",
        validations: Yup.string().optional(),
      },
      {
        fieldName: "valid_from",
        label: "Valid From",
        fieldType: "DATE/TIME",
        valueType: "date",
        validations: Yup.date(),
      },
      {
        fieldName: "valid_to",
        label: "Valid To",
        fieldType: "DATE/TIME",
        valueType: "date",
        validations: Yup.date()
          .test(
            "valid_to",
            "Value should be after valid from date",
            function (value) {
              const { valid_from } = this.parent;
              const valid_to = new Date(value);
              if (valid_from > valid_to) return false;
              return true;
            }
          ),
      },
    ],
  ],
  [
    "thirdRowContainer",
    [
      {
        fieldName: "duration_in_seconds",
        label: "Duration(in seconds)",
        fieldType: "TEXT",
        valueType: "number",
        validations: Yup.number().optional(),
      },
      {
        fieldName: "start_schedule",
        label: "Start Schedule",
        fieldType: "DATE/TIME",
        valueType: "date",
        validations: Yup.date().optional(),
      },
      {
        fieldName: "note",
        label: "Additional Notes",
        fieldType: "TEXTAREA",
        valueType: "string",
        validations: Yup.string().required("This field is required"),
      },
      {
        fieldName: "min_charging_rate",
        label: "Min Charging Rate (decimal, multiple of 0.1)",
        fieldType: "TEXT",
        valueType: "number",
        validations: Yup.number()
        .test("is-decimal","At most, single digit acceptable after decimal point",(value)=>/^\d+(\.\d)?$/.test(String(value)))
        .optional(),
      },
      {
        fieldName: "charging_rate_unit",
        label: "Charging Rate Unit",
        fieldType: "DROPDOWN",
        options: chargingProfileDropdownOptions["charging_rate_unit"],
        valueType: "string",
        validations: Yup.string().required("This field is required"),
      },
    ],
  ],
]);

export const chargingProfileFormValidationSchema = (values) => {
  let schema = [...formConfig.values()]
    .flat()
    .reduce((validationSchema, { fieldName, validations }) => {
      return { ...validationSchema, [fieldName]: validations };
    }, {});
  const schedulePeriodsValidation = Yup.array().of(
    Yup.object().shape({
      start_period: Yup.number()
        .positive("Period must be positive")
        .required("This field is required"),
      power_limit: Yup.number()
        .positive("Limit  must be positiv")
        .test("is-decimal","At most, single digit acceptable after decimal point",(value)=>/^\d+(\.\d)?$/.test(String(value)))
        .required("This field is required"),
      number_phase: Yup.number()
        .positive("Phase must be positive")
        .integer("Phase must be a whole number"),
    })
  );
  schema = { ...schema, schedulePeriods: schedulePeriodsValidation };
  return Yup.object().shape(schema);
};
